<template>
  <a-card
      title="教育经历"
      :bodyStyle="{'height': '400px'}"
  >
    <div slot="extra">
      <a-button size="small" type="primary" style="margin-left: 20px" ghost @click="saveData" v-if="canModify">保存</a-button>
    </div>
    <a-table
        :columns="columns"
        :data-source="data"
        rowKey="id"
        bordered
        :pagination="false"
        :scroll="{ y: 220 }"
    >
      <template slot="beginDate" slot-scope="text, record">
        <template v-if="record.editable">
          <a-date-picker :value="text" @change="(date, dateStr) => itemChange(dateStr, record.id, 'beginDate')" style="width: 100%"/>
        </template>
        <span v-else>{{ text }}</span>
      </template>
      <template slot="endDate" slot-scope="text, record">
        <template v-if="record.editable">
          <a-date-picker :value="text" @change="(date, dateStr) => itemChange(dateStr, record.id, 'beginDate')" style="width: 100%"/>
        </template>
        <span v-else>{{ text }}</span>
      </template>
      <template slot="school" slot-scope="text, record">
        <template v-if="record.editable">
          <a-input
              v-if="record.editable"
              style="margin: -5px 0"
              :value="text"
              @change="e => itemChange(e.target.value, record.id, 'school')"
          />
        </template>
        <span v-else>{{ text }}</span>
      </template>
      <template slot="major" slot-scope="text, record">
        <template v-if="record.editable">
          <a-input
              v-if="record.editable"
              :value="text"
              @change="e => itemChange(e.target.value, record.id, 'major')"
          />
        </template>
        <span v-else>{{ text }}</span>
      </template>
      <template slot="qualifications" slot-scope="text, record">
        <template v-if="record.editable">
          <a-select
              placeholder="请选择学历"
              :value="text"
              @change="e => itemChange(e, record.id, 'qualifications')"
              style="width: 100%"
          >
            <a-select-option value="研究生">研究生</a-select-option>
            <a-select-option value="本科">本科</a-select-option>
            <a-select-option value="专科">专科</a-select-option>
            <a-select-option value="高中">高中</a-select-option>
          </a-select>
        </template>
        <span v-else>{{ text }}</span>
      </template>
      <template slot="academicDegree" slot-scope="text, record">
        <template v-if="record.editable">
          <a-select
              placeholder="请选择学位"
              :value="text"
              @change="e => itemChange(e, record.id, 'academicDegree')"
              style="width: 100%"
          >
            <a-select-option value="博士">博士</a-select-option>
            <a-select-option value="硕士">硕士</a-select-option>
            <a-select-option value="学士">学士</a-select-option>
          </a-select>
        </template>
        <span v-else>{{ text }}</span>
      </template>
      <template slot="id" slot-scope="text, record, index">
        <template v-if="record.editable">
          <img src="@/assets/image/common/save.png" @click="() => save(record.id)" />
          <a-popconfirm title="确定取消?" @confirm="() => cancel(record.id)">
            <img src="@/assets/image/common/cancel.png" alt="" class="margin-l20">
          </a-popconfirm>
        </template>
        <img src="@/assets/image/common/edit.png" alt=""  :disabled="editingKey !== ''" @click="() => edit(record.id)" v-else>
        <a-popconfirm title="确定取消?" @confirm="() => delItem(record, index)">
          <img src="@/assets/image/common/delete.png" alt="" class="margin-l20">
        </a-popconfirm>
      </template>
    </a-table>
    <a-row class="margin-t20">
      <a-form-model
          ref="form"
          layout="inline"
          :model="form"
          @submit="submit"
          @submit.native.prevent
      >
        <a-form-model-item prop="beginDate">
          <a-date-picker :value="form.beginDate ? moment(form.beginDate) : null" @change="(date, dateStr) => formDateChange(dateStr, 'beginDate')" style="width: 150px"/>
        </a-form-model-item>
        <a-form-model-item prop="endDate">
          <a-date-picker :value="form.endDate ? moment(form.endDate) : null" @change="(date, dateStr) => formDateChange(dateStr, 'endDate')" style="width: 150px"/>
        </a-form-model-item>
        <a-form-model-item prop="school">
          <a-input v-model="form.school" type="text" placeholder="请输入院校名称" style="width: 150px"></a-input>
        </a-form-model-item>
        <a-form-model-item prop="major">
          <a-input v-model="form.major" type="text" placeholder="请输入专业名称" style="width: 150px"></a-input>
        </a-form-model-item>
        <a-form-model-item prop="qualifications">
          <a-select v-model="form.qualifications" placeholder="请选择学历" style="width: 150px">
            <a-select-option value="研究生">研究生</a-select-option>
            <a-select-option value="本科">本科</a-select-option>
            <a-select-option value="专科">专科</a-select-option>
            <a-select-option value="高中">高中</a-select-option>
          </a-select>
        </a-form-model-item>
        <a-form-model-item prop="academicDegree">
          <a-select v-model="form.academicDegree" placeholder="请选择学位" style="width: 150px">
            <a-select-option value="博士">博士</a-select-option>
            <a-select-option value="硕士">硕士</a-select-option>
            <a-select-option value="学士">学士</a-select-option>
          </a-select>
        </a-form-model-item>
        <a-form-model-item>
          <a-button
              type="success"
              html-type="submit"
              :disabled="
                    !form.beginDate
                    || !form.endDate
                    || !form.school
                    || !form.major
                    || !form.qualifications
                    || !form.academicDegree"
              class="bg-green width-150"
          >
            确认添加一行
          </a-button>
        </a-form-model-item>
      </a-form-model>
    </a-row>
  </a-card>
</template>

<script>
import { saveStaffEducation } from '../../api/humanResources/staff'
import moment from 'moment'
export default {
  name: 'editEducation',
  props: {
    value: {
      type: [Array, Object],
      default: null
    },
    staffId: {
      type: String,
      default: ''
    },
    canModify: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      data: [],
      cacheData: {},
      editingKey: '',
      form: {},
      columns: [
        {
          title: '开始日期',
          dataIndex: 'beginDate',
          scopedSlots: { customRender: 'beginDate' }
        },
        {
          title: '结束日期',
          dataIndex: 'endDate',
          scopedSlots: { customRender: 'endDate' }
        },
        {
          title: '院校名称',
          dataIndex: 'school',
          scopedSlots: { customRender: 'school' }
        },
        {
          title: '专业名称',
          dataIndex: 'major',
          scopedSlots: { customRender: 'major' }
        },
        {
          title: '学历',
          dataIndex: 'qualifications',
          scopedSlots: { customRender: 'qualifications' }
        },
        {
          title: '学位',
          dataIndex: 'academicDegree',
          scopedSlots: { customRender: 'academicDegree' }
        },
        {
          title: '操作',
          dataIndex: 'id',
          scopedSlots: { customRender: 'id' }
        }
      ],
    }
  },
  mounted () {
    if (this.value) this.data = this.value
    this.cacheData = this.data.map(item => ({ ...item }))
  },
  methods: {
    moment,
    handleChange(value, id, column) {
      const newData = [...this.data];
      const target = newData.find(item => id === item.id);
      if (target) {
        target[column] = value;
        this.data = newData;
      }
    },
    edit(id) {
      const newData = [...this.data];
      const target = newData.find(item => id === item.id);
      this.editingKey = id;
      if (target) {
        target.editable = true;
        this.data = newData;
      }
    },
    save(id) {
      const newData = [...this.data];
      const newCacheData = [...this.cacheData];
      const target = newData.find(item => id === item.id);
      const targetCache = newCacheData.find(item => id === item.id);
      if (target && targetCache) {
        delete target.editable;
        this.data = newData;
        Object.assign(targetCache, target);
        this.cacheData = newCacheData;
      }
      this.editingKey = '';
    },
    cancel(id) {
      const newData = [...this.data];
      const target = newData.find(item => id === item.id);
      this.editingKey = '';
      if (target) {
        Object.assign(target, this.cacheData.find(item => id === item.id));
        delete target.editable;
        this.data = newData;
      }
    },
    submit () {
      this.form.id = new Date().getTime()
      this.data.push(this.form)
      this.form = {}
    },
    itemChange (value, id, column) {
      const newData = [...this.data];
      const target = newData.find(item => id === item.id);
      if (target) {
        target[column] = value;
        this.data = newData;
      }
    },
    delItem (item, index) {
      this.data.splice(index, 1)
    },
    saveData () {
      saveStaffEducation({
        staffEducations: this.data,
        staffId: this.staffId
      }).then(() => {
        this.$emit('change', this.data)
        this.$message.success('保存成功！')
      })
    },
    formDateChange (dateStr, field) {
      this.form[field] = dateStr
      this.$forceUpdate()
    }
  }
}
</script>

<style scoped>

</style>
